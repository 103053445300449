<template>
    <OModal ref="nextStepDlg" >
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="hstack gap-2">
                        <h4 class="modal-title" id="staticBackdropLabel">
                            {{processStepDataObject.current.Name}}
                        </h4>
                        <div v-if="isExecutingProc" class="spinner-border spinner-border-sm" style="0.35rem;" role="status">
                            <span class="visually-hidden">{{ $t('Loading...') }}</span>
                        </div>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">                    
                    <h5 class="modal-title" v-if="!changeResponsible"> {{$t('Who should the workflow be sent to?')}}</h5>
                    <h5 class="modal-title" v-else> {{$t('Who should be responsible for step?')}}</h5>

                    <div v-if="processStepDataObject.current.ChannelSource === null" class="row">                        
                        <ODataLookup v-if="dsProcessStepRoles.data.length > 1"
                            :dataObject="dsProcessStepRoles" 
                            :bind="setRole"
                            disableRecent>
                            <OColumn field="Role" width="300"></OColumn>
                            <template #target="{ target }">
                                <span style="cursor:pointer" class="dropdown-toggle" :ref="target">{{$t("Role")}}: <span>{{ role }}</span></span>
                            </template>
                        </ODataLookup>

                        <div class="mt-3" v-if="workflowDataObject.current.HasRespRoles_ID && !processStepDataObject.current.RestrictStepCompletionToRoleTypeMembers"> {{$t("Select a responsible from an involved party")}}</div>
                        <div class="col-6" v-if="workflowDataObject.current.HasRespRoles_ID && !processStepDataObject.current.RestrictStepCompletionToRoleTypeMembers">
                            <ODataLookup 
                                :bind="onItemsOrgUnitsResp"
                                :dataObject="dsItemsOrgUnitsRespLkp"
                            >
                                <OColumn field="OrgUnit" width="350"></OColumn>
                                <OColumn field="Role" width="300"></OColumn>
                                <OColumn field="Name" width="200"></OColumn>
                                <OColumn field="Represents" width="250"></OColumn>
                                <template #target="{ target }">
                                    <button class="btn btn-sm btn-outline-primary mb-1 dropdown-toggle w-100" :disabled="isExecutingProc" :ref="target">{{$t("Responsible Involved Parties ")}}</button>
                                </template>
                            </ODataLookup>
                        </div>
                        
                        <div class="mt-3"> {{$t("Select a person")}}</div>
                        <div class="col-6" v-if="processStepDataObject.current.Role_ID || dsProcessStepRoles.data.length > 1">
                            <PersonsLookup 
                                :dataObject="dsRoleMembersNS" 
                                :bind="onRoleMemberSelected"
                                :whereClause="`Role_ID = ${roleId} AND OrgUnit_ID = ${props.workflowDataObject.current.OrgUnit_ID} AND ReadOnly = 0`"
                                reloadOnWhereClauseChange
                            >
                                <template #target="{ target }">
                                    <button class="btn btn-sm btn-outline-primary mb-1 dropdown-toggle w-100" :disabled="isExecutingProc || restrictStepToRole" :ref="target">{{$t("Role Members")}}</button>
                                </template>
                            </PersonsLookup>
                        </div>
                        <div class="col-6">
                            <PersonsLookup 
                                v-if="!processStepDataObject.current.RestrictStepCompletionToRoleTypeMembers"
                                :bind="onPersonSelected"
                                :dataObject="dsPersonLkp"
                                :whereClause="personsLkpWhereClause"
                                forceReloadOnOpen
                            >
                                <template #target="{ target }">
                                    <button class="btn btn-sm btn-outline-primary mb-1 dropdown-toggle w-100" :disabled="isExecutingProc || restrictStepToRoleMembers || restrictStepToRole" :ref="target">{{$t("Person With Access")}}</button>
                                </template>
                                <template #toolbarActions>
                                    <OToolbarAction :text="$t('Restrict To Persons With Access')" v-model="restrictPersonsLkpToPersonsWithAccess" />
                                </template>
                            </PersonsLookup>
                            <PersonsLookup 
                                v-else
                                :bind="onPersonSelected"
                                :dataObject="dsPersonsRTRTLkp"
                                :whereClause="personsRTRTLkpWhereClause"
                                forceReloadOnOpen
                            >
                                <template #target="{ target }">
                                    <button class="btn btn-sm btn-outline-primary mb-1 dropdown-toggle w-100" :disabled="isExecutingProc || restrictStepToRoleMembers || restrictStepToRole" :ref="target">{{$t("Person With Access")}}</button>
                                </template>
                            </PersonsLookup>
                        </div>
                        
                        <div class="col-6">
                            <button @click="meSelected" :disabled="isExecutingProc || restrictStepToRoleMembers || restrictStepToRole" class="w-100 btn btn-sm btn-outline-primary mb-1">{{ $t("Me") }}: {{userSession.name}} </button>
                        </div>
                        <div v-if="!workflowSentViaChannel" class="col-6">
                            <button @click="originatorSelected" :disabled="isExecutingProc || restrictStepToRoleMembers || restrictStepToRole" class="w-100 btn btn-sm btn-outline-primary mb-1">{{$t("Originator")}}: {{ workflowDataObject.current.CreatedBy }}</button>
                        </div>

                        <div class="mt-3" v-if="processStepDataObject.current.ForceFixedToPerson == false && changeResponsible == false && roleId !== null">  
                            <div>{{$t("Do not select a person, but ...")}} </div>
                            <button class=" btn btn-sm btn-outline-primary mb-1" :disabled="isExecutingProc" @click="onAllMembersSelected">{{ $t("Send To All Members In Role") }}: {{ role }} </button>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="mt-3"> {{$t("Via channel to")}} <span class="fw-bold">{{ processStepDataObject.current.ChannelSource }}: {{ processStepDataObject.current.ChannelName }}</span></div>
                        <div class="col-6">
                            <PersonsLookup
                                :bind="onPersonSelected"
                                :dataObject="dsPersonLkp"
                                :whereClause="personsLkpWhereClause"
                                forceReloadOnOpen
                            >
                                <template #target="{ target }">
                                    <button class="btn btn-sm btn-outline-primary mb-1 dropdown-toggle w-100" :disabled="isExecutingProc" :ref="target">{{$t("Person With Access")}}</button>
                                </template>
                                <template #toolbarActions>
                                    <OToolbarAction :text="$t('Restrict To Persons With Access')" v-model="restrictPersonsLkpToPersonsWithAccess" />
                                </template>
                            </PersonsLookup>
                        </div>
                    </div>
                
                    <div class="row" v-if="changeResponsible == true && props.stepRow.Person_ID">                        
                        <div class="col-6 mt-2">
                            <button class=" btn btn-sm btn-outline-primary mb-1 w-100" @click="clearResponsible()" >{{$t("Clear Responsible") }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts';
import { ref, defineExpose, computed, watch } from 'vue';
import OModal from 'o365.vue.components.Modal.vue';
import PersonsLookup from 'o365.vue.components.PersonsLookup.vue';
import { userSession } from 'o365.modules.configs.ts';
import Url from 'o365.modules.url.ts';


const emit = defineEmits(["submitted"]);

const props = defineProps({
    workflowDataObject: {
        type: Object,
        required: true
    },
    processStepDataObject: {
        type: Object,
        required: true
    },
    stepRow: {
        type: Object,
        required: false
    },
    itemStepId: {
        type: Number,
        required: false,
    }
});


defineExpose({ show });

// Data object definitions
const dsItemsOrgUnitsLkp = getOrCreateDataObject({
    id: 'dsItemsOrgUnitsLkp' + "_" + crypto.randomUUID(),
    viewName: "aviw_Scope_ItemsOrgUnitsAndSelf",
    maxRecords: -1,
    whereClause: `Item_ID = ${props.workflowDataObject.current.ID}`,
    loadRecents: false,
    distinctRows: true,
    selectFirstRowOnLoad: true,
    fields: [{ name: "OrgUnit" }, { name: "IsCurrent", sortOrder: 1, sortDirection: "desc" }, { name: "OrgUnit_ID" }],
});

const dsRoleMembersNS = getOrCreateDataObject({
    id: 'dsRoleMembersNS' + "_" + crypto.randomUUID(),
    viewName: "aviw_Scope_ItemsStepsPersonsRoleMembers",
    maxRecords: -1,
    loadRecents: false,
    distinctRows: true,
    fields: [{ name: "ID" }, { name: "Name" }, { name: "MobileNo" }, { name: "Email" }, { name: "Represents" }],
});

const dsPersonLkp = getOrCreateDataObject({
    id: 'dsPersonLkp' + "_" + crypto.randomUUID(),
    viewName: "aviw_Scope_PersonsLookup",
    maxRecords: 25,
    whereClause: `Item_ID = ${props.workflowDataObject.current.ID}`,
    loadRecents: false,
    distinctRows: true,
    fields: [{ name: "ID" }, { name: "Name" }, { name: "MobileNo" }, { name: "Email" }, { name: "Represents" }],
});

const dsPersonsRTRTLkp = getOrCreateDataObject({
    id: "dsPersonsRTRTLkp" + "_" + crypto.randomUUID(),
    viewName: "aviw_Scope_PersonsLookupRTRTForProcessStep",
    maxRecords: 25,
    whereClause: `ProcessStep_ID = ${props.processStepDataObject.current.ID} AND Item_ID = ${props.workflowDataObject.current.ID}`,
    loadRecents: false,
    distinctRows: true,
    fields: [{ name: "ID" }, { name: "Name" }, { name: "MobileNo" }, { name: "Email" }, { name: "Represents" }],
});

const dsItemsOrgUnitsRespLkp = getOrCreateDataObject({
    id: 'dsItemsOrgUnitsResp' + "_" + crypto.randomUUID(),
    viewName: "aviw_Scope_ItemsOrgUnitsResponsibles",
    maxRecords: 25,
    whereClause: `Item_ID = ${props.workflowDataObject.current.ID}`,
    loadRecents: false,
    distinctRows: true,
    fields: [{ name: "OrgUnit" }, { name: "Name" }, { name: "ID" }, { name: "Role" }, { name: "Represents" }],
});

const dsProcessStepRoles = getOrCreateDataObject({
    id: 'dsProcessStepRoles' + "_" + crypto.randomUUID(),
    viewName: "aviw_Scope_ProcessesStepsRolesWithDefaultStepRole",
    maxRecords: -1,
    loadRecents: false,
    selectFirstRowOnLoad: true,
    distinctRows: true,
    fields: [{ name: "Role_ID" }, { name: "Role" }],
});

// Procedures
const procMoveStep = getOrCreateProcedure({ id: "procMoveStep", procedureName: "astp_Scope_ItemsStepsMove" });
const procInitiateWorkflow = getOrCreateProcedure({ id: "procInitiate", procedureName: "astp_Scope_ItemInitiate" });

// Refs
const nextStepDlg = ref(null);
const isExecutingProc = ref(false);
const role = ref(null);
const roleId = ref(null);
const changeResponsible = ref(false);
const restrictPersonsLkpToPersonsWithAccess = ref(true);


// Computed values
const currentOrgUnitId = computed(() => dsItemsOrgUnitsLkp.current.OrgUnit_ID);
const isSubmittedItem = computed(() => props.workflowDataObject.current.Submitted);
const restrictStepToRole = computed(() => props.workflowDataObject.current.RestrictToRole);
const restrictStepToRoleMembers = computed(() => props.processStepDataObject.current.RestrictToRoleMembers);
const workflowSentViaChannel = computed(() => props.workflowDataObject.current.Channel !== null);

// Computed lookup where clauses
const personsLkpWhereClause = computed(() => `Item_ID = ${props.workflowDataObject.current.ID} ${restrictPersonsLkpToPersonsWithAccess.value ? 'AND AllPersons = 0' : ''}`);
const personsRTRTLkpWhereClause = computed(() => `ProcessStep_ID = ${props.processStepDataObject.current.ID} AND Item_ID = ${props.workflowDataObject.current.ID}`);

async function show(pChangeResponsible) {
    if(pChangeResponsible){
        changeResponsible.value = true;
    }else{
        changeResponsible.value = false;
    }
    nextStepDlg.value.show();
    
    dsProcessStepRoles.recordSource.whereClause = "Step_ID = " + props.processStepDataObject.current.ID;
    dsProcessStepRoles.load();

    dsItemsOrgUnitsLkp.recordSource.whereClause = `Item_ID = ${props.workflowDataObject.current.ID}`;
    dsItemsOrgUnitsLkp.load();

    dsItemsOrgUnitsRespLkp.recordSource.whereClause = `Item_ID = ${props.workflowDataObject.current.ID}`;
    dsItemsOrgUnitsRespLkp.load();

    roleId.value = props.processStepDataObject.current.Role_ID;    

    setDefaultRole();
}

watch(restrictPersonsLkpToPersonsWithAccess, restrict => {
    dsPersonLkp.recordSource.whereClause = `Item_ID = ${props.workflowDataObject.current.ID} ${restrict ? 'AND AllPersons = 0' : ''}`;
    if (dsPersonLkp.state.isLoaded) {
        dsPersonLkp.load();
    }
});

function setDefaultRole() {
    setRole(props.processStepDataObject.current);
}

function setRole(pRow) {
    roleId.value = pRow.Role_ID;
    role.value = pRow.Role;
}

function onAllMembersSelected() {
    moveStep({ OrgUnit_ID: currentOrgUnitId.value });
}

function onRoleMemberSelected(row) {
    moveStep({ Person_ID: row.ID });
}

function onPersonSelected(row) {
    moveStep({ Person_ID: row.ID });
}

function onItemsOrgUnitsResp(row){
    moveStep({ Person_ID: row.ID });
}

function meSelected() {
    if (isSubmittedItem.value) {
        moveStep({ SetMeAsResp: true });
    } else {
        moveStep({ Person_ID: userSession.personId });
    }
}

function originatorSelected() {
    moveStep({ Person_ID: props.workflowDataObject.current.CreatedBy_ID });
}

async function moveStep(params) {    
    debugger;
    if(changeResponsible.value == false){
        await props.workflowDataObject.save();
        const isExistingItem = !props.workflowDataObject.current.Hidden;
        const procToBeExecuted = isExistingItem ? procMoveStep : procInitiateWorkflow;
        const defaultParams = { 
            ProcessStep_ID: props.processStepDataObject.current.ID,
            OrgUnit_ID: props.workflowDataObject.current.OrgUnit_ID,        
            ...isExistingItem ? {
                // Move existing Item params
                Item_ID: props.workflowDataObject.current.ID,
                ItemStep_ID: props.itemStepId ?? props.workflowDataObject.current.Step_ID ?? props.workflowDataObject.current.CurrentStep_ID,
                Role_ID: roleId.value,
            } : { 
                // Initiate new Item params
                ID: props.workflowDataObject.current.ID,
                ProcessStep_ID: props.processStepDataObject.current.ID,
                OrgUnit_ID: props.workflowDataObject.current.OrgUnit_ID,
                AgendaItemUrl_ID: Url.getParam('AgendaItemUrl_ID'),
                AgendaItem_ID: Url.getParam('AgendaItem_ID')
            }
        };

        isExecutingProc.value = true;
        return procToBeExecuted.execute({ ...defaultParams, ...params }).then(onSubmitted).finally(() => (isExecutingProc.value = false));
    }else{
        if(params.SetMeAsResp){
            props.stepRow.Person_ID = userSession.personId;
        }else{
            props.stepRow.Person_ID = params.Person_ID;
        }
        await props.stepRow.save();
        nextStepDlg.value.hide();
    }
}

async function clearResponsible(){
    props.stepRow.Person_ID = null;
    await props.stepRow.save();
    nextStepDlg.value.hide();
}

function onSubmitted(procResponse) {
    nextStepDlg.value.hide();
    emit("submitted", procResponse);
}

</script>